const trackEvent = (event: string, properties?: Record<string, string>) => {
  if (typeof window !== 'object') {
    return;
  }

  window.dataLayer.push({
    event,
    ...properties
  });
};

export const GTMFaqQuestionClick = () => {
  trackEvent('faq_question-click');
};

export const GTMMyScheduleShareClick = () => {
  trackEvent('my_schedule-share-click');
};

export const GTMScheduleLinkCopyClick = () => {
  trackEvent('schedule-link-copy-click');
};

export const GTMSessionDetailLinkShareClick = () => {
  trackEvent('session-detail_link-share-click');
};

export const GTMSessionAddClick = () => {
  trackEvent('session_add-click');
};

export const GTMSessionDelClick = () => {
  trackEvent('session_del-click');
};
