import { Box } from '@inflearn/ds-react';
import FAQAccordion, { type FAQFormat } from './FAQAccordion';

type Props = {
  faqs: FAQFormat[];
};

const FAQAccordionList = ({ faqs }: Props) => {
  return (
    <Box component="ul">
      {faqs.map((faq, index) => (
        <Box component="li" mb={16} key={`${faq.title}-${index}`}>
          <FAQAccordion {...faq} id={`${faq.title}-${index}`} />
        </Box>
      ))}
    </Box>
  );
};

export default FAQAccordionList;
