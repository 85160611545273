import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import {
  Accordion,
  Icon,
  desktopFirstMediaQuery,
  type MantineTheme,
  useTheme
} from '@inflearn/ds-react';
import React, { useEffect, useState } from 'react';
import { GTMFaqQuestionClick } from '../../../utils/GTM';

type Props = {
  id: string;
} & FAQFormat;

export type FAQFormat = {
  title: string;
  description: React.ReactNode;
};

const FAQAccordion = ({ title, description, id }: Props) => {
  useEffect(() => {
    setIsMount(true);
  }, []);
  const [isMount, setIsMount] = useState(false);
  const theme = useTheme();
  return (
    <Accordion
      variant="separated"
      styles={styleFAQAccordion}
      chevron={<Icon icon={faChevronDown} size={16} color="#666666" width={16} height={16} />}
      onChange={GTMFaqQuestionClick}
      multiple>
      <Accordion.Item value={id}>
        <Accordion.Control css={{ fontWeight: 'bold', color: theme.colors.gray[9], fontSize: 1.5 }}>
          Q. {title}
        </Accordion.Control>
        {isMount ? (
          <Accordion.Panel
            css={{
              color: theme.colors.gray[7],
              fontSize: '16px',
              [desktopFirstMediaQuery.mobile]: {
                fontSize: '14px'
              }
            }}>
            {description}
          </Accordion.Panel>
        ) : (
          ''
        )}
      </Accordion.Item>
    </Accordion>
  );
};

const styleFAQAccordion = (theme: MantineTheme) => ({
  item: {
    backgroundColor: 'white',
    borderColor: `${theme.colors.gray[1]} !important`,
    borderWidth: 1,
    borderStyle: 'solid',
    svg: {
      opacity: 0.5,
      transition: 'opacity 0.3s ease'
    },
    ':hover': {
      svg: {
        opacity: 1
      }
    }
  },
  control: {
    padding: '24px',
    '[aria-expanded="true"]': {
      paddingBottom: '0px'
    }
  },
  label: {
    fontWeight: 700,
    fontSize: '18px',
    padding: 0,
    [desktopFirstMediaQuery.mobile]: {
      fontSize: '16px'
    }
  },
  panel: {
    fontSize: '16px',
    [desktopFirstMediaQuery.mobile]: {
      fontSize: '14px'
    }
  },
  content: {
    padding: '24px',
    paddingTop: 0,
    marginTop: '-4px'
  }
});

export default FAQAccordion;
