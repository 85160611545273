import { css } from '@emotion/react';
import { Box, desktopFirstMediaQuery, Stack } from '@inflearn/ds-react';
import { SegmentedControl } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useState } from 'react';
import { smallerThanSm } from '../../../styles/mediaQueries';
import FAQAccordionList from './FAQAccordionList';

const 참가자_선정_일시 = '2024-07-11 14:00:00';
const 확인증_발급_시작_일시 = '2024-08-05 00:00:00';
const 확인증_발급_종료_일시 = '2024-09-30 23:59:59';

const today = dayjs();
const 참가자선정일시 = dayjs(참가자_선정_일시);
const 확인증발급시작일시 = dayjs(확인증_발급_시작_일시);
const 확인증발급종료일시 = dayjs(확인증_발급_종료_일시);

const isAfter참가자선정일시 = today.isAfter(참가자선정일시);
const is확인증발급기간 = today.isAfter(확인증발급시작일시) && today.isBefore(확인증발급종료일시);

const FAQBody = () => {
  const [value, setValue] = useState<'application' | 'session' | 'etc'>('application');
  const isSmallerThanBreakpointSm = useMediaQuery(smallerThanSm);

  return (
    <Box component="section" css={styleFAQBody}>
      <Stack
        spacing={16}
        css={{
          maxWidth: '1200px',
          width: '100%',
          padding: '0 32px',
          [desktopFirstMediaQuery.mobile]: {
            padding: '0 16px'
          }
        }}>
        <SegmentedControl
          size={isSmallerThanBreakpointSm ? 'md' : 'lg'}
          data={
            isAfter참가자선정일시
              ? [
                  { label: '참가 신청', value: 'application' },
                  { label: '세션 및 행사 참여', value: 'session' },
                  { label: '기타', value: 'etc' }
                ]
              : [
                  { label: '참가 신청', value: 'application' },
                  { label: '기타', value: 'etc' }
                ]
          }
          value={value}
          onChange={(value: 'application' | 'session' | 'etc') => {
            setValue(value);
          }}
          styles={{
            root: {
              width: 'fit-content',
              [desktopFirstMediaQuery.mobile]: {
                width: '100%'
              }
            }
          }}
        />
        {value === 'application' && <FAQAccordionList faqs={APPLICATION_FAQS} />}
        {value === 'session' && isAfter참가자선정일시 ? (
          <FAQAccordionList faqs={SESSION_FAQS} />
        ) : null}
        {value === 'etc' && <FAQAccordionList faqs={ETC_FAQS} />}
      </Stack>
    </Box>
  );
};

const styleFAQBody = css({
  display: 'flex',
  justifyContent: 'center'
});

const APPLICATION_FAQS = [
  {
    title: '참가 신청은 언제 어디서 어떻게 하나요?',
    description: (
      <>
        7월 3일(수) 오후 3시부터 공식 홈페이지의 [참가 신청] 버튼을 통해 신청할 수 있습니다. 또는
        인프런 홈페이지의{' '}
        <a
          href="https://www.inflearn.com/course/%EC%9D%B8%ED%94%84%EC%BD%98-2024"
          target="_blank"
          rel="noreferrer">
          [인프콘 2024 참가 신청 페이지]
        </a>
        에서도 신청이 가능합니다.
        <br />
        <br />
        *참가 신청 후 사전설문 안내 메세지가 발송됩니다. 사전 설문은 신청 시점 24시간 이내
        제출되어야하며, 미 작성시 신청 취소되니 반드시 사전 설문을 제출해주세요.
      </>
    )
  },
  {
    title: '참가 신청은 유료인가요?',
    description:
      '티켓 구매는 유료이며, 추첨으로 참가자를 선정합니다. 추첨 선정자에 한해 참가권이 지급됩니다. 미선정자에게는 자동 환불이 진행됩니다.'
  },
  {
    title: '50% 할인쿠폰은 어떻게 받나요?',
    description: (
      <>
        <a href="https://www.inflearn.com/" target="_blank" rel="noreferrer">
          인프런
        </a>
        에서 유료강의를 1개이상 구매한 유저라면 누구나 할인쿠폰을 받을 수 있습니다. 쿠폰은
        자동발급되며 인프콘 티켓 구매 시, 수강바구니에서 쿠폰을 적용하실 수 있습니다. (
        <a href="https://www.inflearn.com/user/coupons" target="_blank" rel="noreferrer">
          쿠폰함 보러가기
        </a>
        )
        <br />
        <br />
        *해당 할인 쿠폰은 인프콘 티켓 구매 시에만 적용되며, 다른 강의 구매 시에는 미적용됩니다.
      </>
    )
  },
  {
    title: '참가자 발표는 언제 어디서 하나요?',
    description:
      '7월 11일 목요일 오후 2시에 참가 신청 시 제출해 주신 이메일과 휴대폰 번호로 개별 안내 드릴 예정입니다.'
  },
  {
    title: '추첨 미선정자도 참여할 수 있나요?',
    description: '아쉽게도 추첨 미선정자에게는 참가권이 지급되지 않는 점 양해 부탁드립니다.'
  },
  {
    title: '당일 참가 등록도 가능한가요?',
    description: '사전 신청만 가능하며, 당일 신청 및 등록은 불가능합니다.'
  },
  {
    title: '인프콘 참가 신청 후, 취소 및 환불이 가능할까요?',
    description:
      '인프콘 2024 취소 및 환불 기간은 인프콘 2024 참가 신청 기간과 동일합니다. 참가 신청 기간이 지난 이후의 취소 및 환불은 불가합니다. '
  },
  {
    title: '인프콘 티켓을 다른 사람에게 판매해도 되나요?',
    description:
      '인프콘 참가 티켓을 중고나라 등 온라인에서 되파는 것은 금지하고 있습니다. 적발시 이에 따른 불이익이 있을 수 있는 점 참고 부탁드립니다.'
  }
];

const SESSION_FAQS = [
  {
    title: '참가자로 선정되었습니다. 행사 당일 어떻게 입장하나요?',
    description:
      '행사 전일(8월 1일)에 참가 등록 및 입장을 위한 QR 코드가 문자로 발송될 예정입니다. 행사장 등록 부스에서 수신하신 QR코드를 미리 준비해주시길 바랍니다. 문자를 미수신한 경우 등록 부스의 안내 데스크에서 직접 신청 정보를 확인하신 후 입장이 가능하니 참고 부탁드립니다.'
  },
  {
    title: '당일 입장 시, 행사 시작시간보다 늦어도 입장이 가능한가요?',
    description:
      '인프콘 등록 부스는 행사 1시간 전인, 오전 9시부터 오후 4시까지 (마지막 세션 전까지) 운영됩니다. 해당 시간 내에 도착하시면 입장이 가능합니다.'
  },
  {
    title: '듣고 싶은 발표 세션, 부스는 어떻게 참여하나요?',
    description: (
      <>
        인프콘 참가자로 선정되셨다면, 공식 홈페이지의{' '}
        <a href="https://www.inflearn.com/conf/session" target="_blank" rel="noreferrer">
          타임 테이블
        </a>
        을 참고하여 원하시는 세션과 부스에 자유롭게 참여해 주시면 됩니다. 모든 세션은 별도의 사전
        신청 없이 자유롭게 들을 수 있습니다.
      </>
    )
  },
  {
    title: '행사에 참여하지 못하게 됐는데, 티켓 양도가 가능한가요?',
    description:
      '만약 행사 참여가 어려운 경우, QR코드 전달 등으로 타인에게 참여권을 양도할 수 있습니다. 다만, 양도받는 분의 정보로 변경 및 등록이 불가하며 처음 신청한 분의 정보로 등록 및 입장이 가능한 점 참고 부탁드립니다. 더불어 다른 참석자에게 해가 되는 No-Show는 최대한 지양해주시길 바랍니다.'
  },
  {
    title: '인프콘2024 녹화 영상이 제공되나요?',
    description:
      '행사 종료 후 인프런 홈페이지에 행사 및 발표 세션 녹화 영상이 업로드 될 예정입니다.'
  }
];

const ETC_FAQS_OPENED_AFTER_참가자선정 = [
  {
    title: '점심 식사가 제공 되나요?',
    description:
      '점심 식사는 제공되지 않습니다. 행사장 내부에서 취식이 불가하니 가까운 식당에서 개별적으로 식사 부탁드립니다.'
  },
  {
    title: '행사장에서 Wifi와 충전기를 이용할 수 있나요?',
    description:
      '코엑스 공용 무료 와이파이를 이용할 수 있으며, 별도의 충전 공간은 제공되지 않습니다.'
  },
  {
    title: '주차 가능한가요?',
    description:
      '행사 참가자분들을 위한 별도의 주차 공간이 마련되어 있지 않아, 가급적 대중교통을 이용하시는 것을 권장 드립니다.'
  },
  {
    title: '인프콘 행사 당일 촬영 안내',
    description:
      '행사 당일 인프콘 2024 발표 세션, 부스 프로그램 등 행사장 내부에서 사진과 영상 촬영이 진행됩니다. 촬영된 사진과 영상은 마케팅 용도로 사용될 수 있는 점 미리 양해 부탁드립니다.'
  }
];

const ETC_FAQS = [
  ...(isAfter참가자선정일시 ? ETC_FAQS_OPENED_AFTER_참가자선정 : []),
  {
    title: '행사 참가 확인증 발급이 되나요?',
    description: is확인증발급기간 ? (
      <>
        가능합니다. 참가확인증은 9월 30일까지만 발급이 가능하니, 확인 후 기간 내에 발급
        부탁드립니다.
        <br />
        {/* TODO 링크 수정 */}
        <a href="https://www.inflearn.com/" target="_blank" rel="noreferrer">
          참가확인증 발급 바로가기
        </a>
      </>
    ) : (
      '인프콘 참가확인증은 행사 종료 후 8월 6일부터 발급이 가능합니다. 발급 링크는 추후 확인하실 수 있습니다. (해당 FAQ에 발급링크 공지 예정)'
    )
  }
];

export default FAQBody;
