import { Box } from '@inflearn/ds-react';
import { type HeadFC } from 'gatsby';
import FAQBody from '../../components/infcon-2024/FAQ/FAQBody';
import PageTitle from '../../components/infcon-2024/PageTitle';
import { useViewInfconMain } from '../../hooks/useViewInfconMain';
import { INFCON_2024_COLOR_PALETTE } from '../../styles/color';
import { GATSBY_SITE_URL } from '../../utils/env';
import { getCdnUrl } from '../../utils/getCdnUrl';
import Seo from '../../utils/Seo';
import { URLS } from '../../utils/urls';

export const Head: HeadFC = () => {
  return (
    <Seo
      title="인프콘 2024 - FAQ"
      description="자주 묻는 질문을 확인해보세요"
      fullUrl={`${GATSBY_SITE_URL}/conf${URLS.INFCON_2024__FAQ}`}
      image={getCdnUrl('infcon-2024-main/official-og.png')}
    />
  );
};

const FAQ = () => {
  useViewInfconMain();
  return (
    <Box bg={INFCON_2024_COLOR_PALETTE.backgroundGray}>
      <PageTitle title={'인프콘 FAQ'} subTitle={'자주 묻는 질문'} color={'gray.9'} />
      <FAQBody />
    </Box>
  );
};

export default FAQ;
